<div *ngIf="openSelection" (click)="toggleSelection()" class="selection-basket-overlay"></div>
<div class="search-menus">

  <div class="search-menus__match-count-container" id="search_result_count">
    @if (searchContainer.searchResult?.search_count !== undefined && searchContainer.searchResult?.search_count !== null) {
      <div class="search-menus__match-count-container__count">
        {{searchContainer.searchResult?.search_count}} {{'TRANS__SEARCH_MENUS__MATCHES_IN' | translate}} «{{searchContainer.currentPathView.search_view.title | translate}}»
      </div>
      @if (searchContainer.searchResult?.stats?.stats_fields && searchContainer.searchResult?.stats?.stats_fields['count.count'].sum > 0) {
        <div class="search-menus__totals-count">{{searchContainer.searchResult?.stats?.stats_fields['count.count'].sum}} {{'TRANS__SEARCH_VIEWS__MENU__OBJECTS' | translate | lowercase}} <mat-icon class="search-menus__help-icon" matTooltip="{{'TRANS__SEARCH_TOTALS__TOOLTIP' | translate}}">help</mat-icon> </div>
      }
    } @else {
      <span class="search-menus__match-count-container__count">
        {{'TRANS__HIERARCHIC_SEARCH_PANEL__SEARCHING' | translate}}
      </span>
    }
  </div>



  <ul class="search-menus__button-group">
    <li class="search-menus__selection-basket">
      <button class="search-menus__selection-basket-button" [matMenuTriggerFor]="selectionBasket">
        <span class="search-menus__selection-basket-count-bg-container">
          <span class="search-menus__selection-basket-count-bg" *ngIf="selectedCount !== 0">
            <span>{{selectedCount}}</span></span>
          <mat-icon class="search-menus__selection-basket-icon">inbox</mat-icon></span>
        <span class="search-menus__selection-basket-button-text" translate="TRANS__SEARCH_MENU__SELECTION"></span>
        <mat-icon>expand_more</mat-icon>
      </button>
    </li>
    <li class="search-top-menu-buttons search-menus__button search-menus__button__search-action flex items-center" [ngClass]="{
      'disable': actionMenuDisabled, 'active':searchContainer.toggleActionMenu}">
      <app-search-menu-select-all class="markAllContainer"
        [searchContainer]="searchContainer"></app-search-menu-select-all>
      <app-search-menu-actions class="search-menus__button__search-action-menu" [searchContainer]="searchContainer"
        [operations]="searchContainer.operationContainer?.operations" [disabled]="actionMenuDisabled"
        [reportContainer]="searchContainer.reportContainer"
        (operationPerformed)="onOperationPerformed($event)"></app-search-menu-actions>
    </li>
  </ul>

  <ul class="search-menus__button-group search-menus__button-group__view">
    <li class="search-top-menu-buttons search-menus__button__view">
      <app-dropdown-menu-animated class="density--1 search-menus__button__content" [menuContainer]="{
          view: currentResultView,
          buttonClass : 'new-button',
          disableContainer: searchContainer.reportContainer,
          disableProp: 'show'}">

        <app-search-menu-view-selector class="search-menus__button__content" [searchContainer]="searchContainer"
          [currentResultView]="currentResultView"></app-search-menu-view-selector>

      </app-dropdown-menu-animated>
    </li>

    <li class="search-top-menu-buttons search-menus__button__sort">
      <app-dropdown-menu-animated class="density--1 search-menus__button__content" [menuContainer]="{
          view: { description: 'TRANS__SEARCH__ORDER_ON',  icon: 'sort', name: searchContainer.searchResultViewName},
          disableContainer: searchContainer.reportContainer,
          buttonClass : 'new-button',
          disableProp: 'show'}">

        <app-search-menu-sort [searchContainer]="searchContainer"></app-search-menu-sort>
      </app-dropdown-menu-animated>
    </li>
    <li *ngIf="searchContainer.path === 'home/folders/folder'"
      class="search-top-menu-buttons search-menus__button__folder-settings">
      <app-search-menu-folder-settings class="search-menus__button__content" [searchContainer]="searchContainer"
        (operationPerformed)="onOperationPerformed($event)"></app-search-menu-folder-settings>
    </li>


    <mat-menu #selectionBasket="matMenu" class="search-menus__selection-basket-dropdown">
      <app-search-selection-basket (removeSelectionItem)="remove($event)" (removeAllItems)="removeAll()"
        [searchContainer]="searchContainer" [selectedCount]="selectedCount"></app-search-selection-basket>
    </mat-menu>
  </ul>

</div>
