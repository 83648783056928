<div class="main-menu">
  <div class="main-menu__logo" [routerLink]="'/home'">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path fill="currentColor" d="M19.241 1.74075C17.8381.577113 15.7877 0 13.0944 0H6v24h4.7109V3.8569h2.1395c1.2857 0 2.2288.26276 2.8341.78826.6005.52551.9055 1.34194.9055 2.44458 0 1.09325-.3613 1.92374-1.0791 2.49618-.6475.51618-1.5953.79768-2.8387.84928v4.1431c2.6181-.1502 7.0662-.99 8.2393-4.82816.0234-.07508.0422-.14547.0656-.22052.0329-.10791.061-.21115.0892-.30968.1502-.56775.2346-1.18241.2628-1.8393.0047-.15483.0187-.30498.0187-.46451-.0046-2.28972-.7038-4.01644-2.1068-5.17538ZM1 14.5642h4.05293v-4.298H1v4.298Z"/>
    </svg>
  </div>
  <div class="main-menu__button-group main-menu__button-group--primary">
    @for(operation of primaryOperations; track $index) {
      <div class="main-menu__nav-button">
        @if(operation.router_link !== 'search') {
          <button
            mat-icon-button
            routerLinkActive="main-menu__nav-button--active"
            [queryParams]="getQueryParams()"
            [routerLink]="operation.router_link"
          >
            <mat-icon>
              {{operation.icon}}
            </mat-icon>
          </button>
        }
        @else {
          <button
            mat-icon-button
            (click)="goToSearch()"
            [ngClass]="{'main-menu__nav-button--active' : currentStateName === 'home.primus.search' }"
          >
            <mat-icon>
              {{operation.icon}}
            </mat-icon>
          </button>
        }

        <span class="main-menu__nav-label">
          {{operation.name | translate}}
        </span>
      </div>
    }
  </div>

  <!--@if(currentStateName !== 'home') {-->
    <div class="main-menu__button-group main-menu__button-group--secondary">
      <app-messages-and-notification-v2 />
      @for(operation of secondaryOperations; track $index) {
        @if(operation.router_link !== 'faq' && operation.router_link !== 'operations') {
          <button mat-icon-button matTooltipPosition="after" routerLinkActive="main-menu__nav-button--active"
            [queryParams]="getQueryParams()" [routerLink]="operation.router_link" [matTooltip]="operation.name | translate">
            <mat-icon class="main-menu__filled-icon">
              {{operation.icon}}
            </mat-icon>
          </button>
          }
          @else if (operation.router_link === 'faq') {
          <button mat-icon-button matTooltipPosition="after" (click)="showFaq(true)" [matTooltip]="operation.name | translate">
            <mat-icon class="main-menu__filled-icon">
              {{operation.icon}}
            </mat-icon>
          </button>
          }
          @else if(operation.router_link === 'operations') {
          <button mat-icon-button class="main-menu__operation-button" matTooltipPosition="after"
            [matMenuTriggerFor]="operationsMenu" [matTooltip]="operation.name | translate" (click)="jobsFinished = 0">
            @if (!jobStarted && jobsFinished === 0) {
            <mat-icon class="main-menu__filled-icon">all_inbox</mat-icon>
            }

            <!-- 2️⃣ all_inbox with badge -->
            @else if (!jobStarted && jobsFinished > 0) {
            <mat-icon class="main-menu__filled-icon" matBadge="{{jobsFinished}}" matBadgeSize="large"
              [matBadgeHidden]="false">all_inbox</mat-icon>
            }

            <!-- 3️⃣ sync with spinner without badge -->
            @else if (jobStarted && jobsFinished === 0) {
            <mat-icon class="main-menu__filled-icon spinner"></mat-icon>
            }

            <!-- 4️⃣ sync with spinner and badge -->
            @else if (jobStarted && jobsFinished > 0) {
            <mat-icon class="main-menu__filled-icon spinner" matBadge="{{jobsFinished}}" matBadgeSize="large"
              [matBadgeHidden]="false"></mat-icon>
            }
          </button>
          }
          }
          </div>
  <!--} -->

  <div class="main-menu__button-group main-menu__button-group--tertiary">
    @if(userImageUrl !== '') {
      <img
        class="main-menu__user-image"
        (click)="goToCurrentUser()"
        [src]="userImageUrl"
      />
    }
    @else {
      <div
        class="main-menu__user-image-label"
        (click)="goToCurrentUser()"
      >
        <span class="main-menu__user-image-label-text">
          {{getUserInitials()}}
        </span>
      </div>
    }
    <span class="main-menu__user-label">
      {{ 'TRANS__MAIN_MENU__YOU' | translate }}
    </span>
  </div>
</div>

<mat-menu class="main-menu__operations-panel" #operationsMenu="matMenu" xPosition="after" yPosition="above">
  <app-job-status-v2
    [activeJobs]="activeJobs"
  />
</mat-menu>
