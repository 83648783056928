<form class="search-query" (submit)="submit()">

  <div *ngIf="newLayout && !mediumScreen"
       class="search-query__new-layout row "> <!--new layout-->
    @if (!useNewLayout) {
      <div class="search-query__input">
        <div class="search-icon-container" *ngIf="!hideIcon">
          <span class="search-icon"><i class="icon-search"></i></span>
        </div>
        @if (queryContainer.selectedQueryMenu?.query_type === 'field_search') {
          <app-search-query-field-search
            [queryPlaceholder]="queryPlaceholder"
            [searchContainer]="searchContainer"
          ></app-search-query-field-search>
        }
        @else if (queryContainer.selectedQueryMenu?.query_type === 'advanced') {
          <app-search-query-extended-search
            [searchContainer]="searchContainer"
          />
        }
        @else {
          <input type="text"
                 id="searchQueryNew"
                 autocomplete="off"
                 [ngClass]="{'errorInputFrame': searchCount === 0 && queryContainer.query && queryContainer.query.length > 0 }"
                 [(ngModel)]="query"
                 [ngModelOptions]="{standalone: true}"
                 (ngModelChange)="queryChanged()"
                 [placeholder]="queryPlaceholder">
          <a class="clear-input-field"
             (click)="clearSearch()">
            <mat-icon>close</mat-icon></a>
        }
      </div>
    }
    @else {
      @if (queryContainer.selectedQueryMenu?.query_type === 'advanced') {
        <app-search-query-extended-search
          [searchContainer]="searchContainer"
        />
      }
      @else {
        <div class="search-query__input">
          <div class="search-icon-container" *ngIf="!hideIcon">
            <span class="search-icon"><i class="icon-search"></i></span>
          </div>
          @if (queryContainer.selectedQueryMenu?.query_type === 'field_search') {
            <app-search-query-field-search
              [queryPlaceholder]="queryPlaceholder"
              [searchContainer]="searchContainer"
            ></app-search-query-field-search>
          }
          @else {
            <input type="text"
                   id="searchQueryNew"
                   autocomplete="off"
                   [ngClass]="{'errorInputFrame': searchCount === 0 && queryContainer.query && queryContainer.query.length > 0 }"
                   [(ngModel)]="query"
                   [ngModelOptions]="{standalone: true}"
                   (ngModelChange)="queryChanged()"
                   [placeholder]="queryPlaceholder">
            <a class="clear-input-field"
               (click)="clearSearch()">
              <mat-icon>close</mat-icon></a>
          }
        </div>
      }
    }

    @if (!pathAsFilter && useNewLayout) {
      <button
        mat-button
        (click)="toggleMenu()"
        [matMenuTriggerFor]="newMenu"
      >
        <div>
          <span>
            {{ !!searchContainer.searchCategory ? searchContainer.searchCategory.category.name : (queryContainer.selectedQueryMenu?.description | translate) }}
          </span>
          <span class="search-query__search-count">
            &nbsp;({{searchCount}})
          </span>
        </div>
        @if (pathViewMenus.menus.length > 0) {
          <mat-icon matSuffix>
            {{ showMenu ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        }
      </button>
    }

    @else if (!pathAsFilter && !useNewLayout) {
      <div class="search-query-inline-menu search-input-menu-trigger">
        <button type="button"
                [matMenuTriggerFor]="newMenu"
                (click)="toggleMenu()"
                [ngClass]="{'active': showMenu, 'notButton': pathViewMenus.menus.length < 1}"
                class="search-query-inline-menu__button">
          <span class="vertical-line"></span>
          <span class="search-query-inline-menu__button-content">
          <span class="flex-grow search-query-inline-menu__button-content-text">
            <span> {{ !!searchContainer.searchCategory ? searchContainer.searchCategory.category.name : (queryContainer.selectedQueryMenu?.description | translate) }}</span>
            <span>({{searchCount}})</span></span>
            <mat-icon
              *ngIf="pathViewMenus.menus.length > 0"
              class="search-query-inline-menu__button-icon">{{showMenu ? 'expand_less' : 'expand_more'}}</mat-icon></span>
        </button>
      </div>
    }

    <app-search-query-path-menu
      *ngIf="pathAsFilter"
      [showPathMenu]="showPathMenu"
      [searchContainer]="searchContainer"
      (menuPathSet)="onMenuPathSet($event)"
    ></app-search-query-path-menu>
  </div>

  <div *ngIf="!newLayout || mediumScreen"
       class="search-query__old-layout"
       [ngClass]="{'noBorder': noBorder}"> <!--old layout: used in selector and on small screens-->
    <div class="search-query__input">
      <div class="search-query-pop-up-menu-button"
           [ngClass]="{'notButton': pathViewMenus.menus.length < 1}">
        <button class="search-query-pop-up-menu-button__content"
                (click)="toggleMenu()"
                type="button"
                [matMenuTriggerFor]="newMenu">
          <span class="search-icon"
                [class.text-blue]="showMenu"
                [ngClass]="{'text-blue-hover': pathViewMenus.menus.length > 0}">
            <mat-icon>search</mat-icon>
            <mat-icon *ngIf="pathViewMenus.menus.length > 0"
                      class="search-icon-arrow">arrow_drop_down</mat-icon>
          </span>
        </button>
      </div>
      <input type="text"
             id="searchQueryOld"
             *ngIf="queryContainer.selectedQueryMenu?.query_type !== 'advanced'"
             [ngClass]="{'errorInputFrame': searchCount === 0 && queryContainer.query && queryContainer.query.length > 0 }"
             [(ngModel)]="query"
             (change)="queryChanged()"
             autocomplete="off"
             [ngModelOptions]="{standalone: true}"
             placeholder="{{queryPlaceholder}} ({{searchCount}} {{'TRANS__HEADER__RESULTS' | translate}})">
      <a *ngIf="queryContainer.query && queryContainer.selectedQueryMenu?.query_type !== 'advanced'"
         class="clear-input-field"
         (click)="clearSearch()">
        <mat-icon>close</mat-icon></a>
    </div>
  </div>

  <mat-menu #newMenu="matMenu"
            yPosition="below"
            (closed)="showMenu = false;"
            class="removePaddingInMatMenu search-input-menu-drop-down">
    <div *ngFor="let menu of pathViewMenus.menus">
      <button mat-menu-item
              *ngIf="menu.menus"
              type="button"
              (click)="togglePathViewMenus(menu); $event.stopPropagation();"
              class="menu-button-with-icons">
        <span class="bold flex-grow">{{ menu.description | translate }}</span>
        <mat-icon>{{menu.query_type === selectedPathViewMenu ? 'expand_less' : 'expand_more'}}</mat-icon>
      </button>
      <div *ngIf="menu.menus &&  menu.query_type === selectedPathViewMenu">
        <div *ngIf="menu.query_type === 'search_category'" class="">
          <button mat-menu-item
                  type="button"
                  *ngFor="let search of storedSearchCategoriesForUser"
                  [ngClass]="{'active': search.category.name === searchContainer?.searchCategory?.category?.name}"
                  class="menu-button-with-icons">
            <a (click)="useSearchCategory(search, true)"
               class="flex-grow sub-menu-item">
              <mat-icon class="search-input-menu-drop-down__icon">find_in_page</mat-icon>
              <span>{{ search.category.name }}</span></a>

            <a class="sub-menu-item"
               (click)="deleteSearchCategory(search)">
              <mat-icon class="search-input-menu-drop-down__icon" color="warn">delete_outline</mat-icon>
            </a>

            <a class="sub-menu-item"
               (click)="editOrCreateSearchCategory(search)">
              <mat-icon class="search-input-menu-drop-down__icon">edit</mat-icon>
            </a>
          </button>
        </div>

        <div *ngIf="menu.menus">
          <button mat-menu-item
                  (click)="selectQueryMenu(subMenuItem)"
                  *ngFor="let subMenuItem of menu.menus"
                  [ngClass]="{'active': subMenuItem.selected}">

            <i *ngIf="subMenuItem.icon_class" [class]="subMenuItem.icon_class"></i>

            <mat-icon class="search-input-menu-drop-down__icon" *ngIf="subMenuItem.icon">{{ subMenuItem.icon }}</mat-icon>

            <span>{{ subMenuItem.description | translate }}</span>

          </button>
        </div>
      </div>
      <button mat-menu-item
              *ngIf="!menu.menus"
              (click)="selectQueryMenu(menu)"
              [ngClass]="{'active': menu.selected}">
        <span>{{ menu.description | translate }}</span>
      </button>
    </div>
  </mat-menu>

</form>
