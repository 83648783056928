import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AccessTokenService} from './access-token.service';
import {ObjectView, ObjectViewAndData, ObjectAndOperations} from './definitions/object-view';
import {ConceptType, ConceptTypes} from './definitions/concept-types';
import {Concept, SearchConcepts, ConceptsParams} from './definitions/concepts';
import {Setting} from './definitions/setting';
import {CopyObjectParams} from './definitions/copy-object-params';
import {SuperObjectModel} from './definitions/super-object-model';
import {SearchParameters, SearchParametersForOverview} from './definitions/search-parameters';
import {
  GetArtifactParams,
  GetArtifactViewParams,
  GetArtifactViewAndDataParams, GetArtifactViewAndDataForReportParams
} from './definitions/get-artifact-params';
import {GetArtifactsParams} from './definitions/get-artifacts-params';
import {ImageItems} from './definitions/image-items';
import {SearchResult} from './definitions/search-result';
import {ContextList} from './definitions/context-list';
import {ConceptSetting} from './definitions/concept-setting';
import {IdentifierFormat} from '../administration/admin-id-format/identifier-format';
import {ModelUploadInfo} from './definitions/upload-info';
import {
  MetaOperationDef,
  MetaOperationExecuteStatus,
  MetaOperationList,
  MetaOperationValidation
} from './definitions/meta-operation-def';
import {TemplateGroup} from './definitions/template-models';
import {OverviewFieldsFromIdsParams} from './definitions/overview-fields-from-ids-params';
import {OverviewFieldsFromIdsRes} from './definitions/overview-fields-from-ids-res';
import {GetOperationsParams} from './definitions/get-operations-params';
import {OperationList} from './definitions/operation-list';
import {OperationStepExecutionParams} from './definitions/operation-step-execution-params';
import {OperationStepExecutionResult} from './definitions/operation-step-execution-result';
import {OperationStepCancelResult} from './definitions/operation-step-cancel-result';
import {SettingSpectrumProcedure} from './definitions/setting-spectrum-procedure';
import {GenerateIdentifierParams} from './definitions/generate-identifier-params';
import {GenerateIdentifierResult} from './definitions/generate-identifier-result';
import {TemplateGroupInfo} from './definitions/template-group-info';
import {GetOperationObjectsParams} from './definitions/get-operation-objects-params';
import {
  QueryMenu,
  RootSearchView,
  SearchViewFilters,
  SearchViewMenu,
  SortOrderMenu
} from './definitions/search-objects';
import {Section} from './definitions/sections-container';
import {ImageUrl, ImageUrls} from './definitions/image-url';
import {MetaField} from './definitions/meta-field';
import {OperationStep} from './definitions/operation-step';
import {PrimusBackendInstanceService} from './primus-backend-instance.service';
import {CanAddNewResult} from './definitions/can-add-new-result';
import {CanDeleteResult} from './definitions/can-delete-result';
import {PutArtifactResult} from './definitions/put-artifact-result';
import {Focus} from './definitions/focus';
import {SearchCategory} from './definitions/extended-search/search-category';
import {ContentMenuData} from './definitions/object-content-tab/content-menus';
import {GetArtifactAndOperationsParams} from './get-artifact-and-operations-params';
import {LoggerService} from './logger.service';
import {SearchObject} from './definitions/search-object';
import {CanDeleteMultipleResult} from './definitions/can-delete-multiple-result';
import {CanDeleteTemplateResult} from './definitions/can-delete-template-result';
import {ObjectSearchFiltersResult} from './definitions/object-search-filters-result';
import {ConfigValue} from './definitions/config-value';
import {CultureHubFolder} from './definitions/culture-hub-folder';
import {UpdateCultureHubFolderParams} from './definitions/update-culture-hub-folder-params';
import {OrderDownloadUrlResult} from './definitions/order-download-url-result';
import {InlineViewItem} from "./definitions/inline-view-item";
import {Reference} from "./definitions/reference";
import {FieldIf} from "./definitions/field-if";
import {OptionInfo} from "./definitions/option-info";
import {FieldAction} from "./definitions/field-action";
import {DateInfo} from "./definitions/date-info";
import {ObjectUsageParams} from "./definitions/object-usage-params";
import {FeatureFlags} from "./definitions/feature-flags";
import {catchError} from "rxjs/operators";
import {UrlData} from "./definitions/url-data";
import {GetImageUrlsParams} from "./definitions/get-image-urls-params";
import {GetSolrFieldsParams} from "./definitions/get-solr-fields-params";
import {SolrField} from "./definitions/solr-field";
import {v4 as uuid} from 'uuid';
import {ExtendedSearchFields, GetExtendedSearchFieldParams} from "./definitions/extended-search-field";
import {ModelSchema} from "./definitions/model-schema";
import {DashboardListCategory} from './definitions/dashboard-list-category';
import {ExtendedFieldQueryOperator, SearchSuggestion} from "./definitions/extended-search-params";
import {HibernationService, TIMEOUT_TO_CHECK_HEALTH_AFTER_SLOW_CMS_API_MS} from '../shared/hibernation.service';
import {DamsImportData} from "./definitions/dams-import-data";

@Injectable({
  providedIn: 'root'
})
export class CmsApiService {

  constructor(private readonly http: HttpClient,
              private readonly logger: LoggerService,
              private readonly accessTokenService: AccessTokenService,
              private readonly hibernationService: HibernationService,
              private readonly primusBackendInstanceService: PrimusBackendInstanceService) {
  }

  private readonly cmsApiPath = '/cms_api/v1.0/';
  private loginFn = null;
  private errHandler = null;
  API_IMAGE_ORDER_UPLOAD_URL = 'multimedia/image/order_upload_url/';

  private static getUrl(httpParams) {
    let url = httpParams.url;
    if (httpParams.params) {
      let prefix = '?';
      for (const key in httpParams.params) {
        if (httpParams.params.hasOwnProperty(key)) {
          const value = httpParams.params[key];
          if (value !== undefined) {
            url += prefix + key + '=' + value;
            prefix = '&';
          }
        }
      }
    }
    return url;
  }

  init(errHandler, loginFn?) {
    this.errHandler = errHandler;
    this.loginFn = loginFn;
  }

  getFetchUrl(call_name, addApiPath: boolean, queryString: string, noThrowError?: boolean) {
    const apiUrl = PrimusBackendInstanceService.getApiUrl();
    if (!apiUrl) {
      if (noThrowError) {
        return null;
      }
      throw new Error('[CMS-API-SERVICE] -- API URL not set');
    }
    let url = addApiPath ? `${apiUrl}${this.cmsApiPath}${call_name}` : `${apiUrl}/${call_name}`;
    if (queryString) {
      url += queryString
    }
    return url;
  }

  getApiUrl(call_name, addApiPath: boolean, noThrowError?: boolean) {
    const apiUrl = PrimusBackendInstanceService.getApiUrl();
    if (!apiUrl) {
      if (noThrowError) {
        return null;
      }
      throw new Error('[CMS-API-SERVICE] -- API URL not set');
    }
    return addApiPath ? `${apiUrl}${this.cmsApiPath}${call_name}` : `${apiUrl}/${call_name}`;
  }

  eventSave(data): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!data) {
        resolve();
      }

      this.execAjax(
        'eventdata',
        {
          contentType: 'application/json',
          type: 'POST',
          data: data
        },
        () => {
          resolve();
        },
        (e: Error) => {
          reject(e);
        }
      );
    });
  }

  logout(params?) {
    return new Promise((resolve, reject) => {
      this.execFetch('logout',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__LOG_OUT'
        },
        (response) => {
          if (isDevMode()) {
            this.logger.debug('[CMS-SERVICE] -- API logged off');
          }
          resolve(response);
        },
        (error: Error) => {
          reject(error);
        }
      );
    });
  }


  getSettings(params?): Promise<Array<Setting>> {
    return new Promise((resolve, reject) => {
      this.execFetch('setting/get_settings', {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_SETTINGS',
        type: 'GET'
      }, resolve, reject);
    });
  }

  async getClientConfig(params?) {
    return new Promise((resolve, reject) => {
      this.execFetch('setting/get_client_config', {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_CLIENT_CONFIG',
        suppressErrHandler: true
      }, resolve, reject);
    });
  }

  async getConfig(params): Promise<ConfigValue> {
    return new Promise((resolve, reject) => {
      this.execFetch('setting/get_config/' + params.key,
        {
          suppressErrHandler: true
        }, resolve, reject);
    });


  }

  async getAvailableVirtualCollections(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.execFetch('setting/available_virtual_collections', {
        data: {},
        clientMsg: 'TRANS__ERROR__GETTING_AVAILABLE_VIRTUAL_COLLECTIONS'
      }, resolve, reject);
    });
  }

  async getUserData(params?) {
    return new Promise((resolve, reject) => {
      const myParams = params || {};
      this.execFetch('setting/get_user_config', {
        clientMsg: 'TRANS__ERROR__GETTING_USER_DATA',
        suppressErrHandler: myParams.suppressErrHandler
      }, resolve, reject);
    });
  }

  async getSettingConcept(conceptType): Promise<ConceptSetting> {
    return new Promise((resolve, reject) => {
      this.execFetch('setting/get_setting_concept/' + conceptType, {
        clientMsg: 'TRANS__ERROR__GETTING_SETTING_CONCEPT'
      }, resolve, reject);
    });
  }

  async getFeatureFlags(): Promise<FeatureFlags> {
    return new Promise((resolve, reject) => {
      this.execFetch('setting/feature_flags', {
        clientMsg: 'TRANS__ERROR__GETTING_FEATURE_FLAGS',
        suppressErrHandler: true
      }, resolve, reject);
    });
  }

  async putSetting(setting: Setting) {
    return new Promise((resolve, reject) => {
      this.execFetch('setting/put_setting', {
        data: setting,
        clientMsg: 'TRANS__ERROR__PUTTING_SETTING',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  async putSettingConcept(conceptSetting: ConceptSetting) {
    return new Promise((resolve, reject) => {
      this.execFetch('setting/put_setting_concept', {
        data: conceptSetting,
        clientMsg: 'TRANS__ERROR__PUTTING_SETTING_CONCEPT',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  async getSpectrumProcedureSetting(params): Promise<SettingSpectrumProcedure> {
    return new Promise<SettingSpectrumProcedure>((resolve, reject) => {
      this.execFetch('setting/spectrum_procedure_setting/' + params.spectrum_procedure_id, {
        clientMsg: 'TRANS__ERROR__GETTING_SPECTRUM_PROCEDURE_SETTING'
      }, resolve, reject);
    });
  }

  async getContentMenus(): Promise<ContentMenuData> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/content_menus', {
        clientMsg: 'TRANS__ERROR__GETTING_CONTENT_MENUS',
        suppressErrHandler: true
      }, resolve, reject);
    });
  }

  /*
  params: meta_type_ids, list of meta type ids used for restricting
   the list of artifact types to be returned (optional)
   */
  async getSuperObjectTypeIdsFromMetaTypeIds(params): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/superobject_type_ids_from_meta_type_ids', {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_SUPEROBJECT_TYPES',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  async getAllSearchReferences(): Promise<{ [id: string]: Reference }> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/search_references', {
        clientMsg: 'TRANS__ERROR__GETTING_SEARCH_REFERENCES',
        suppressErrHandler: true
      }, resolve, reject);
    });
  }

  async getFieldConditions(): Promise<{ [id: string]: FieldIf[] }> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/field_conditions', {
        clientMsg: 'TRANS__ERROR__GETTING_FIELD_CONDITIONS',
        suppressErrHandler: true
      }, resolve, reject);
    })
  }

  async getValueOptions(): Promise<{ [id: string]: OptionInfo }> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/value_options', {
        clientMsg: 'TRANS__ERROR__GETTING_VALUE_OPTIONS',
        suppressErrHandler: true
      }, resolve, reject);
    })
  }

  async getFieldActions(params): Promise<FieldAction[]> {
    return new Promise((resolve, reject) => {
      this.execFetch(`meta/field_actions/${params.actions_id}`, {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_FIELD_ACTIONS',
        suppressErrHandler: true
      }, resolve, reject);
    });
  }

  /*
   params: artifact_id: artifact id
   */
  async getArtifact(params: GetArtifactParams): Promise<SuperObjectModel> {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/get/' + params.artifact_id, {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT',
        transValues: {objectId: params.artifact_id},
        suppressErrHandler: params.suppressErrHandler
      }, resolve, reject);
    });
  }

  async getArtifactView(params: GetArtifactViewParams): Promise<ObjectView> {
    return new Promise(((resolve, reject) => {
      this.execFetch('artifact/view/' + params.artifact_id, {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_VIEW',
        transValues: {objectId: params.artifact_id}
      }, resolve, reject);
    }));
  }

  async getArtifactViewAndData(params: GetArtifactViewAndDataParams): Promise<ObjectViewAndData> {
    return new Promise(((resolve, reject) => {
      this.execFetch('artifact/view_and_data/' + params.artifact_id, {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_VIEW_AND_DATA',
        transValues: {objectId: params.artifact_id}
      }, resolve, reject);
    }));
  }

  async gerArtifactViewAndDataForReport(params: GetArtifactViewAndDataForReportParams): Promise<ObjectViewAndData[]> {
    return new Promise(((resolve, reject) => {
      this.execStream('artifact/view_and_data_for_report/' + params.report_id, {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_VIEW_AND_DATA_FOR_REPORT',
        transValues: {objectId: params.report_id}
      }, resolve, reject);
    }));
  }

  async getObjectAndOperations(params: GetArtifactAndOperationsParams): Promise<ObjectAndOperations> {
    return new Promise(((resolve, reject) => {
      this.execFetch('meta_operation/object_and_operations', {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_AND_OPERATIONS',
        type: 'POST',
        contentType: 'application/json',
        transValues: {objectId: params.artifact_id}
      }, resolve, reject);
    }));
  }

  /**
   * @param params
   */
  async copyArtifact(params: CopyObjectParams): Promise<SuperObjectModel> {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/copy/' + params.artifact_id, {
        data: params,
        clientMsg: 'TRANS__ERROR__COPYING_ARTIFACT',
        transValues: {objectId: params.artifact_id}
      }, resolve, reject);
    });
  }

  /*
   params:
   artifact_ids: array of artifact ids
   */
  async getArtifacts(params: GetArtifactsParams): Promise<SuperObjectModel[]> {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/artifacts', {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_ARTIFACTS',
        type: 'POST',
        contentType: 'application/json',
        suppressErrHandler: params.suppressErrHandler
      }, resolve, reject);
    });
  }

  async getArtifactImages(params): Promise<ImageItems> {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/images/' + params.artifact_id, {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_IMAGE',
        transValues: {objectId: params.artifact_id}
      }, resolve, reject);
    });
  }

  async getArtifactUsage(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/usage/' + params.artifact_id, {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_ARTIFACT_USAGE',
        transValues: {objectId: params.artifact_id}
      }, resolve, reject);
    });
  }

  async saveArtifact(params): Promise<PutArtifactResult> {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/put', {
        data: params.artifact,
        clientMsg: 'TRANS__ERROR__SAVING_ARTIFACT',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  async canDelete(params): Promise<CanDeleteResult> {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/can_delete/' + params.artifact_id, {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_CAN_DELETE',
        transValues: {objectId: params.artifact_id},
        suppressErrHandler: true
      }, resolve, reject);
    });
  }

  async canDeleteMultiple(params): Promise<CanDeleteMultipleResult> {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/can_delete_multiple',
        {
          data: params,
          type: 'POST',
          clientMsg: 'TRANS__ERROR__GETTING_CAN_DELETE_MULTIPLE',
          suppressErrHandler: true
        }, resolve, reject);
    });
  }

  async canDeleteTemplate(params): Promise<CanDeleteTemplateResult> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/template/can_delete/' + params.template_group_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_CAN_DELETE',
          transValues: {objectId: params.template_group_id}
        }, resolve, reject);
    });
  }

  async saveUser(params: any) {
    return new Promise((resolve, reject) => {
      this.execFetch('user/save', {
        data: params,
        clientMsg: 'TRANS__ERROR__SAVING_ARTIFACT',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  async createArtifact(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/create/' + params.object_type, {
        clientMsg: 'TRANS__ERROR__SAVING_ARTIFACT',
      }, resolve, reject);
    });
  }

  async saveSubArtifact(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/sub_artifact', {
        data: params.subArtifact,
        clientMsg: 'TRANS__ERROR__SAVING_ARTIFACT',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  async saveSubArtifacts(contextList: ContextList) {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/sub_artifacts', {
        data: contextList,
        clientMsg: 'TRANS__ERROR__SAVING_ARTIFACT',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  async deleteArtifact(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/delete/' + params.artifact_id, {
        data: {},
        clientMsg: 'TRANS__ERROR__DELETING_ARTIFACT',
        transValues: {objectId: params.artifact_id},
        type: 'DELETE'
      }, resolve, reject);
    });
  }

  async getCanAddNew(params): Promise<CanAddNewResult> {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/can_add_new/' + params.object_type, {
        clientMsg: 'TRANS__ERROR__CAN_ADD_NEW',
        suppressErrHandler: true
      }, resolve, reject);
    });
  }

  async identifierExists(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('setting/identifier_exists',
        {
          data: params,
          suppressErrHandler: true,
          clientMsg: 'TRANS__ERROR__CHECKING_EXISTING_IDENTIFIER',
          transValues: {identifier: params.identifier},
          type: 'POST',
          contentType: 'application/json'
        },
        resolve, reject);
    });
  }

  async getNextIdentifier(params: GenerateIdentifierParams): Promise<GenerateIdentifierResult> {
    return new Promise((resolve, reject) => {
      this.execFetch('setting/get_next_identifier/' + params.superobject_type_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__CREATING_IDENTIFIER'
        }, resolve, reject);
    });
  }

  async search(params: SearchParameters): Promise<SearchResult> {
    return new Promise((resolve, reject) => {
      this.execFetch('search/search', {
        data: params,
        clientMsg: 'TRANS__ERROR__SEARCHING',
        type: 'POST',
        contentType: 'application/json',
        forceRetries: true
      }, resolve, reject);
    });
  }

  async searchWithOverview(params: SearchParametersForOverview): Promise<SearchResult> {
    return new Promise((resolve, reject) => {
      this.execFetch('search/with_overview', {
        data: params,
        clientMsg: 'TRANS__ERROR__SEARCHING',
        type: 'POST',
        contentType: 'application/json',
        forceRetries: true
      }, resolve, reject)
    });
  }

  async searchDbWithOverview(params: SearchParametersForOverview): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('search/db_with_overview', {
        data: params,
        clientMsg: 'TRANS__ERROR__SEARCHING',
        type: 'POST',
        contentType: 'application/json',
        forceRetries: true
      }, resolve, reject)
    });
  }

  async getDbSearchCount(params: SearchParametersForOverview): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('search/db_search_count', {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_SEARCH_COUNT',
        type: 'POST',
        contentType: 'application/json',
        forceRetries: true
      }, resolve, reject)
    });
  }

  async getCachedSearchData(correlationId: string): Promise<SearchResult> {
    return new Promise((resolve, reject) => {
      this.execFetch('search/cached_search_data/' + correlationId, {
        clientMsg: 'TRANS__ERROR__GETTING_CACHED_SEARCH_DATA',
        suppressErrHandler: true
      }, resolve, reject)
    });
  }

  async deleteCachedSearchData(correlationId: string): Promise<SearchResult> {
    return new Promise((resolve, reject) => {
      this.execFetch('search/delete_cached_search_data/' + correlationId, {
        clientMsg: 'TRANS__ERROR__DELETING_CACHED_SEARCH_DATA',
        type: 'DELETE',
        suppressErrHandler: true
      }, resolve, reject)
    });
  }

  async cancelDbSearch(correlationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('search/cancel_db_search/' + correlationId, {
        clientMsg: 'TRANS__ERROR__CANCELLING_DB_SEARCH',
        type: 'DELETE'
      }, resolve, reject)
    })
  }

  async searchForReferenceUsage(params: any): Promise<SearchObject[]> {
    return new Promise((resolve, reject) => {
      this.execFetch('search/reference_usage', {
        data: params,
        clientMsg: 'TRANS__ERROR__SEARCHING_REFERENCE_USAGE',
        type: 'GET',
        suppressErrHandler: true
      }, resolve, reject)
    });
  }

  async sendNotificationEmail(params: any): Promise<SearchResult> {
    return new Promise((resolve, reject) => {
      this.execFetch('notification_emails', {
        data: params,
        clientMsg: 'TRANS__ERROR__SEARCHING',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject)
    });
  }

  async getSearchView(viewName): Promise<RootSearchView> {
    return new Promise<any>((resolve, reject) => {
      this.execFetch('meta/search/view/' + viewName,
        {
          clientMsg: 'TRANS__ERROR__GETTING_SEARCH_VIEW',
          transValues: {viewName: viewName},
          suppressErrHandler: true
        }, resolve, reject
      );
    });
  }

  async getSearchViewQueryMenus(): Promise<{ [name: string]: QueryMenu }> {
    return new Promise<any>((resolve, reject) => {
      this.execFetch('meta/search/view_query_menus',
        {
          clientMsg: 'TRANS__ERROR__GETTING_SEARCH_VIEW_QUERY_MENUS',
          suppressErrHandler: true
        }, resolve, reject
      );
    });
  }

  async getSearchViewSortOrderMenus(): Promise<{ [name: string]: SortOrderMenu[] }> {
    return new Promise<any>((resolve, reject) => {
      this.execFetch('meta/search/view_sort_order_menus',
        {
          clientMsg: 'TRANS__ERROR__GETTING_SEARCH_VIEW_SORT_ORDER_MENUS',
          suppressErrHandler: true
        }, resolve, reject
      );
    });
  }

  async getSearchViewCategoryMenus(): Promise<{ [name: string]: SearchViewMenu[] }> {
    return new Promise<any>((resolve, reject) => {
      this.execFetch('meta/search/view_category_menus',
        {
          clientMsg: 'TRANS__ERROR__GETTING_SEARCH_VIEW_CATEGORY_MENUS',
          suppressErrHandler: true
        }, resolve, reject
      );
    });
  }

  async getSearchViewFilters(): Promise<SearchViewFilters> {
    return new Promise<any>((resolve, reject) => {
      this.execFetch('meta/search/filters',
        {
          clientMsg: 'TRANS__ERROR__GETTING_SEARCH_FILTERS',
          suppressErrHandler: true
        }, resolve, reject
      );
    });
  }

  async getInlineView(params): Promise<InlineViewItem[]> {
    return new Promise<any>((resolve, reject) => {
      this.execFetch('meta/inline_view/' + params.objectType,
        {
          clientMsg: 'TRANS__ERROR__GETTING_INLINE_VIEW',
          transValues: {objectType: params.objectType},
          suppressErrHandler: true
        }, resolve, reject
      );
    });
  }

  async getFaq(params?) {
    return new Promise((resolve, reject) => {
      this.execFetch('translation/faq' + (params ? '/' + params.category : ''),
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_FAQ',
          suppressErrHandler: true
        },
        resolve, reject);
    });
  }

  getToolTip(params): Promise<object> {
    return new Promise((resolve, reject) => {
      this.execFetch('translation/tool_tip/' + params.field_uuid,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_TOOL_TIP',
          suppressErrHandler: true
        },
        resolve, reject);
    });
  }

  getUpcomingVersion(params?) {
    return new Promise((resolve, reject) => {
      this.execFetch('translation/upcoming_version',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_UPCOMING_VERSION',
          suppressErrHandler: true
        },
        resolve, reject);
    });
  }

  getMessage(params?) {
    return new Promise((resolve, reject) => {
      this.execFetch('translation/message',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_MESSAGE',
          suppressErrHandler: true
        },
        resolve, reject);
    });
  }

  async getImageUrl(params): Promise<ImageUrl> {
    return new Promise(((resolve, reject) => {
      this.execFetch('multimedia/image_url/' + params.image_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_IMAGE_URL',
        }, resolve, reject);
    }));
  }

  async getImageUrls(params: GetImageUrlsParams): Promise<ImageUrls> {
    return new Promise(((resolve, reject) => {
      this.execFetch('multimedia/image_urls',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_IMAGE_URL',
          type: 'POST',
          contentType: 'application/json',
          forceRetries: true
        }, resolve, reject);
    }));
  }

  async getImageUrlsByDmsIds(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('multimedia/image_urls_by_dms_ids',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_IMAGE_URLS_WITH_DMS_IDS',
          type: 'POST',
          contentType: 'application/json',
          forceRetries: true
        }, resolve, reject);
    });
  }

  async orderPlaybackUrls(params): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.execFetch(`multimedia/order_playback/${params.artifact_id}`,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_PLAYBACK_URL'
        }, resolve, reject);
    });
  }

  async getUploadStatus(params): Promise<string> {
    return new Promise((resolve, reject) => {
      this.execFetch('multimedia/upload_status/' + params.artifact_id,
        {
          // data: params,
          clientMsg: 'TRANS__ERROR__GETTING_VIDEO_UPLOAD_STATUS',
          suppressErrHandler: true
        }, resolve, reject);
    });
  }

  async getUploadProgress(params): Promise<number> {
    return new Promise((resolve, reject) => {
      this.execFetch('multimedia/upload_progress/' + params.artifact_id,
        {
          // data: params,
          clientMsg: 'TRANS__ERROR__GETTING_VIDEO_UPLOAD_PROGRESS',
          suppressErrHandler: true
        }, resolve, reject);
    });
  }

  async getImageUploadUrl(params): Promise<UrlData> {
    return new Promise((resolve, reject) => {
      this.execFetch(this.API_IMAGE_ORDER_UPLOAD_URL + params.fileName,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_IMAGE_UPLOAD_URL'
        }, resolve, reject);
    });
  }

  async getVideoUploadUrl(params): Promise<UrlData> {
    return new Promise((resolve, reject) => {
      this.execFetch('multimedia/video/order_upload_url/' + params.fileName,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_VIDEO_UPLOAD_URL'
        }, resolve, reject);
    });
  }

  async getAttachmentUploadUrl(params): Promise<UrlData> {
    return new Promise((resolve, reject) => {
      this.execFetch('multimedia/attachment/order_upload_url/' + params.fileName,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_ATTACHMENT_UPLOAD_URL'
        }, resolve, reject);
    });
  }

  async getAudioUploadUrl(params): Promise<UrlData> {
    return new Promise((resolve, reject) => {
      this.execFetch('multimedia/audio/order_upload_url/' + params.fileName,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_AUDIO_UPLOAD_URL'
        }, resolve, reject);
    });
  }

  async get3dModelUploadUrl(params): Promise<UrlData> {
    return new Promise((resolve, reject) => {
      this.execFetch('multimedia/model_3d/order_upload_url/' + params.fileName,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_3D_MODEL_UPLOAD_URL'
        }, resolve, reject);
    });
  }

  // Currently not in use, but will as soon as Pydantic models are fully implemented on client
  async getModelSchema(params): Promise<ModelSchema> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/model_schema/' + params.modelName,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_MODEL_SCHEMA',
          transValues: {modelName: params.modelName}
        },
        resolve, reject);
    });
  }

  async getModels(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/models',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_MODELS',
          suppressErrHandler: true
        },
        resolve, reject);
    });
  }

  async getModelSections(params): Promise<Section[]> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/model_sections/' + params.object_type,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_MODELS',
          suppressErrHandler: true
        },
        resolve, reject);
    });
  }

  async getModelOverviewFields(params): Promise<MetaField[]> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/model_overview_fields/' + params.modelName,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_OVERVIEW_FIELDS',
          transValues: {modelName: params.modelName}
        }, resolve, reject);
    });
  }

  async getTemplateOverviewFields(params): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/template_overview_fields/' + params.template_group_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_TEMPLATE_OVERVIEW_FIELDS'
        }, resolve, reject);
    });
  }

  async getOverviewFieldsFromIds(params: OverviewFieldsFromIdsParams): Promise<Array<OverviewFieldsFromIdsRes>> {
    return new Promise((resolve, reject) => {
      this.execFetch('search/overview_fields_from_ids',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_OVERVIEW_FIELDS_FROM_IDS',
          type: 'POST',
          contentType: 'application/json',
          forceRetries: true
        }, resolve, reject);
    });
  }

  async getSolrFields(params: GetSolrFieldsParams): Promise<SolrField[]> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/solr_fields',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_SOLR_FIELDS'
        },
        resolve, reject);
    });
  }

  async getAdvancedSearchFields(params: GetExtendedSearchFieldParams): Promise<ExtendedSearchFields> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/advanced_search_fields',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_ADVANCED_SEARCH_FIELDS'
        },
        resolve, reject);
    });
  }

  async getAdvancedSearchFieldValues(solrField: string, params: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.execFetch(`meta/advanced_search_field_values/${solrField}`,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_ADVANCED_SEARCH_FIELD_VALUES'
        },
        resolve, reject);
    });
  }

  async getModelIcons() {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/model_icons',
        {
          clientMsg: 'TRANS__ERROR__GETTING_MODEL_ICONS',
          suppressErrHandler: true
        },
        resolve, reject);
    });
  }

  getNewModelInfo(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/new_model_info/' + params.modelName,
        {
          clientMsg: 'TRANS__ERROR__GETTING_NEW_MODEL_INFO',
          transValues: {modelName: params.modelName}
        },
        resolve, reject);
    });
  }

  async getModelRelations(params) {
    return new Promise(((resolve, reject) => {
      this.execFetch('meta/model_relations/' + params.modelName, {
        clientMsg: 'TRANS__ERROR__GETTING_MODEL_RELATIONS',
        transValues: {modelName: params.modelName},
        suppressErrHandler: true
      }, resolve, reject);
    }));
  }

  getModelUploadInfo(params): Promise<ModelUploadInfo> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/model_upload_info/' + params.modelName,
        {
          clientMsg: 'TRANS__ERROR__GETTING_MODEL_UPLOAD_INFO',
          suppressErrHandler: true
        },
        resolve, reject);
    });
  }

  getCultureHubAuthoritiesList(params): Promise<CultureHubFolder[]> {
    return new Promise((resolve, reject) => {
      this.execFetch('kulturnav/authorities' +
        (params.listType ? '/' + params.listType : ''),
        {
          clientMsg: 'TRANS__ERROR__GETTING_AUTHORITIES_LIST',
          transValues: {listType: params.listType}
        },
        resolve, reject);
    });
  }

  saveCultureHubAuthoritiesList(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('kulturnav/authorities', {
        clientMsg: 'TRANS__ERROR__SAVING_AUTHORITIES_LIST',
        data: params,
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  async searchCultureHub(params): Promise<Array<any>> {
    return new Promise(((resolve, reject) => {
      this.execFetch('kulturnav/search/' + params.query, {
        suppressErrHandler: params.suppressErrHandler,
        clientMsg: 'TRANS__ERROR__SEARCHING_KULTURNAV',
        data: params,
        type: 'POST',
        contentType: 'application/json',
        forceRetries: true
      }, resolve, reject);
    }));
  }

  async importFromCultureHub(params): Promise<any> {
    return new Promise((resolve, reject) => {
      const importParams = params.concept_type_id ? `${params.uuid},${params.concept_type_id}` : params.uuid;
      this.execFetch(`kulturnav/import_entity/${importParams}`, {
        suppressErrHandler: params.suppressErrHandler,
        clientMsg: 'TRANS__ERROR__IMPORTING_FROM_KULTURNAV'
      }, resolve, reject);
    });
  }

  async retrieveCultureHubFolders(params): Promise<CultureHubFolder[]> {
    return new Promise((resolve, reject) => {
      this.execFetch(`kulturnav/retrieve_folders/${params.concept_type_id}`, {
        suppressErrHandler: params.suppressErrHandler,
        clientMsg: 'TRANS__ERROR__RETRIEVING_KULTURNAV_FOLDERS'
      }, resolve, reject);
    });
  }

  async updateCultureHubFolder(params: UpdateCultureHubFolderParams): Promise<CultureHubFolder[]> {
    return new Promise((resolve, reject) => {
      this.execFetch('kulturnav/update_folder', {
        suppressErrHandler: params.suppressErrHandler,
        clientMsg: 'TRANS__ERROR__UPDATING_KULTURNAV_FOLDERS',
        data: params,
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  downloadSitulaDatasets(refresh) {
    return new Promise((resolve, reject) => {
      const url = ((!refresh) ? 'situla/datasets' : 'situla/import_datasets');

      this.execFetch(url,
        {
          clientMsg: 'TRANS__ERROR__GETTING_SITULA_DATASETS'
        }, resolve, reject);

    });
  }

  importSitulaDataset(id) {
    return new Promise((resolve, reject) => {
      this.execFetch('situla/import_dataset/' + id, {
        type: 'GET'
      }, resolve, reject);
    });
  }

  deleteSitulaDataset(id) {
    return new Promise((resolve, reject) => {
      this.execFetch('situla/delete_dataset/' + id, {
        type: 'DELETE'
      }, resolve, reject);
    });
  }

  getObjectStatusTypes() {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/object_status_types',
        {
          clientMsg: 'TRANS__ERROR__GETTING_OBJECT_STATUS_TYPES',
          suppressErrHandler: true
        },
        resolve, reject);
    });
  }

  async getUserTemplateGroupInfo(): Promise<TemplateGroupInfo> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/user_template_group_info',
        {
          clientMsg: 'TRANS__ERROR__GETTING_USER_TEMPLATE_GROUP_INFO'
        },
        resolve, reject);
    });
  }

  async getTemplateGroup(params): Promise<TemplateGroup> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/template/get_group/' + params.artifact_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_TEMPLATE_GROUP',
          transValues: {objectId: params.artifact_id}
        }, resolve, reject);
    });
  }

  async copyTemplateGroup(params): Promise<TemplateGroup> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/template/copy_group/' + params.artifact_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__COPYING_TEMPLATE_GROUP',
          transValues: {objectId: params.artifact_id}
        }, resolve, reject);
    });
  }

  async putTemplateGroup(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/template/put_group', {
        data: params.templateGroup,
        clientMsg: 'TRANS__ERROR__PUTTING_TEMPLATE_GROUP',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  async deleteTemplateGroup(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/template/delete_group/' + params.templateGroupId, {
        data: {},
        clientMsg: 'TRANS__ERROR__DELETING_TEMPLATE_GROUP',
        transValues: {templateGroupId: params.templateGroupId},
        type: 'DELETE'
      }, resolve, reject);
    });
  }

  async globalTemplateExists(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/template/global_template_exists',
        {
          clientMsg: 'TRANS__ERROR__CHECKING_GLOBAL_TEMPLATE_EXISTS',
        }, resolve, reject);
    });
  }

  async getMetaObject(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/meta_object/' + params.objectType,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_META_OBJECT',
          transValues: {objectType: params.objectType}
        }, resolve, reject);
    });
  }

  async putMetaObject(params) {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/meta_object', {
        data: params.object,
        clientMsg: 'TRANS__ERROR__PUTTING_META_OBJECT',
        type: 'POST',
        contentType: 'application/json'
      }, resolve, reject);
    });
  }

  async getMetaView(params): Promise<ObjectView> {
    return new Promise(((resolve, reject) => {
      this.execFetch('meta/view/' + params.artifact_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_META_VIEW',
          transValues: {objectId: params.artifact_id}
        }, resolve, reject);
    }));
  }

  getUpdateLog(params): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.execFetch('artifact/update_log/' + params.contextId,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_UPDATE_LOG',
          transValues: {artifactId: params.contextId},
          suppressErrHandler: true
        }, resolve, reject);
    });
  }

  async getConceptTypes(params?): Promise<ConceptTypes> {
    return new Promise<ConceptTypes>((resolve, reject) => {
      this.execFetch('concept/concept_types', {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_CONCEPT_TYPES',
        suppressErrHandler: true
      }, resolve, reject);
    });
  }

  async getConceptType(params): Promise<ConceptType> {
    return new Promise<ConceptType>((resolve, reject) => {
      this.execFetch(`concept/concept_type/${params.concept_type_id}`, {
        data: params,
        clientMsg: 'TRANS__ERROR__GETTING_CONCEPT_TYPE',
        suppressErrHandler: true
      }, resolve, reject);
    });
  }

  async getConcepts(params: ConceptsParams): Promise<SearchConcepts> {
    return new Promise<SearchConcepts>((resolve, reject) => {
      this.execFetch('concept/concepts/' + params.concept_type_id,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_CONCEPTS',
        }, resolve, reject);
    });
  }

  async putConcept(params: Concept): Promise<Concept> {
    return new Promise<Concept>((resolve, reject) => {
      this.execFetch('concept/put',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__STORING_CONCEPT',
          type: 'POST',
          contentType: 'application/json'
        }, resolve, reject);
    });
  }

  async getConceptUsage(params: ObjectUsageParams): Promise<{ [name: string]: SearchObject[] }> {
    return new Promise<any>((resolve, reject) => {
      this.execFetch('concept/concept_usage',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_CONCEPT_USAGE',
          type: 'POST',
          contentType: 'application/json',
          suppressErrHandler: true
        }, resolve, reject);
    });
  }

  async getConceptFieldUsage(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.execFetch('concept/concept_field_usage',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_CONCEPT_FIELD_USAGE',
          suppressErrHandler: true
        }, resolve, reject);
    });
  }

  // Focuses
  async getStoredFocusesForUser(): Promise<Array<Focus>> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/focus/focuses', {
        type: 'GET',
      }, resolve, reject);
    });
  }

  async createOrUpdateFocus(focus: Focus): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/focus', {
        type: 'POST',
        contentType: 'application/json',
        data: focus
      }, resolve, reject);
    });
  }

  async deleteFocus(focusId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/focus/' + focusId, {
        type: 'DELETE',
      }, resolve, reject);
    });
  }

  // Search categories
  async getStoredSearchCategoriesForUser(): Promise<Array<SearchCategory>> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/search-category/categories', {
        type: 'GET',
      }, resolve, reject);
    });
  }

  async createOrUpdateSearchCategory(category: SearchCategory): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/search-category', {
        type: 'POST',
        contentType: 'application/json',
        data: category
      }, resolve, reject);
    });
  }

  async deleteSearchCategory(categoryId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/search-category/' + categoryId, {
        type: 'DELETE',
      }, resolve, reject);
    });
  }

  async getFieldQueryOperators(): Promise<ExtendedFieldQueryOperator[]> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/advanced_search/field_query_operators', {
        type: 'GET',
        suppressErrHandler: true
      }, resolve, reject);
    });
  }

  async getSearchSuggestions(): Promise<SearchSuggestion[]> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/advanced_search/search_suggestions', {
        type: 'GET',
        suppressErrHandler: true
      }, resolve, reject);
    });
  }

  public async getMetaFieldById(fieldId): Promise<MetaField> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/field_by_id/' + fieldId, {}, resolve, reject);
    });
  }

  public async getObjectSearchFilters(metaType: string, objectType: string): Promise<ObjectSearchFiltersResult> {
    return new Promise(((resolve, reject) => {
      this.execFetch(`meta/object_search_filters/${metaType},${objectType}`, {}, resolve, reject);
    }));
  }

  public async getFieldDateInfo(): Promise<{ [id: string]: DateInfo }> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta/field_date_info', {
        suppressErrHandler: true
      }, resolve, reject);
    });
  }

  // IdentifierFormat

  public async getAllIdentifierFormat(): Promise<Array<IdentifierFormat>> {
    return new Promise<Array<IdentifierFormat>>((resolve, reject) =>
      this.execFetch('setting/get_identifier_formats',
        {},
        resolve,
        reject));
  }

  public async createIdentifierFormat(body: IdentifierFormat): Promise<IdentifierFormat> {
    const res = await new Promise<any>((resolve, reject) =>
      this.execFetch('setting/put_identifier_format', {
        type: 'POST',
        contentType: 'application/json',
        data: body
      }, resolve, reject)
    );
    if (res) {
      body.artifact_id = res.artifact_id;
    } else {
      throw res;
    }
    return body;
  }

  public async deleteIdentifierFormat(formatId: string): Promise<string> {
    return new Promise<string>((resolve, reject) =>
      this.execFetch(`setting/delete_identifier_format/${formatId}`, {
        type: 'DELETE',
      }, resolve, reject)
    );
  }

  async getOperations(params: GetOperationsParams): Promise<OperationList> {
    return new Promise((resolve, reject) =>
      this.execFetch('meta_operation/operations', {
        type: 'POST',
        contentType: 'application/json',
        data: params,
        suppressErrHandler: true
      }, resolve, reject));
  }

  async getNextOperationStep(queueId): Promise<OperationStep> {
    return new Promise((resolve, reject) => {
      this.execFetch('meta_operation/next_operation_step/' + queueId, {}, resolve, reject);
    });
  }

  async getOperationsByObjectType(objectType: string): Promise<MetaOperationList> {
    return new Promise((resolve, reject) =>
      this.execFetch('meta_operation/operations_by_object_type/' + objectType, {
        suppressErrHandler: true
      }, resolve, reject));
  }

  async getOperationObject(params: GetOperationObjectsParams): Promise<SuperObjectModel> {
    return new Promise<SuperObjectModel>(((resolve, reject) => {
      this.execFetch('meta_operation/operation_object', {
        type: 'POST',
        contentType: 'application/json',
        data: params
      }, resolve, reject);
    }));
  }

  async validateOperation(metaOperationDef: MetaOperationDef): Promise<MetaOperationValidation> {
    return new Promise<MetaOperationValidation>((resolve, reject) => {
      this.execFetch('meta_operation/validate_operation',
        {
          data: metaOperationDef,
          clientMsg: 'TRANS__ERROR__VALIDATING_META_OPERATION',
          type: 'POST',
          contentType: 'application/json'
        }, resolve, reject);
    });
  }

  async executeOperationStep(operationStepExecutionParams: OperationStepExecutionParams): Promise<OperationStepExecutionResult> {
    return new Promise<OperationStepExecutionResult>((resolve, reject) => {
      this.execFetch('meta_operation/execute_operation_step',
        {
          data: operationStepExecutionParams,
          clientMsg: 'TRANS__ERROR__EXECUTING_OPERATION_STEP',
          type: 'POST',
          contentType: 'application/json'
        }, resolve, reject);
    });
  }

  async cancelOperationStep(operationStepExecutionParams: OperationStepExecutionParams): Promise<OperationStepCancelResult> {
    return new Promise<OperationStepCancelResult>((resolve, reject) => {
      this.execFetch('meta_operation/cancel_operation_step',
        {
          data: operationStepExecutionParams,
          clientMsg: 'TRANS__ERROR__CANCELLING_OPERATION_STEP',
          type: 'POST',
          contentType: 'application/json'
        }, resolve, reject);
    });
  }

  async executeOperation(metaOperationDef: MetaOperationDef): Promise<MetaOperationExecuteStatus> {
    return new Promise<MetaOperationExecuteStatus>((resolve, reject) => {
      this.execFetch('meta_operation/execute_operation',
        {
          data: metaOperationDef,
          clientMsg: 'TRANS__ERROR__EXECUTING_META_OPERATION',
          type: 'POST',
          contentType: 'application/json'
        }, resolve, reject);
    });
  }

  async orderDownloadUrl(artifactId: string): Promise<OrderDownloadUrlResult> {
    return new Promise((resolve, reject) => {
      this.execFetch(`multimedia/order_download_url/${artifactId}`, {}, resolve, reject);
    });
  }

  async getServiceRegister(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.execFetch('service_register',
        {
          clientMsg: 'TRANS__ERROR__GETTING_SERVICE_REGISTER'
        }, resolve, reject);
    });
  }

  // TODO: replace fetch used in HibernationService with this
  // Only the check types 'all' | 'db'| 'solr' | 'fake_down' are allowed
  async checkHealth(checkType: string = 'all'): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.execFetch('healthcheck/check_health/' + checkType,
        {
          clientMsg: 'TRANS__ERROR__GETTING_HEALTH_CHECK',
          skipApiPath: true
        }, resolve, reject);
    });
  }

  async executeService(path, params, method, contentType, skipApiPath: boolean, stream: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.execFetch(path,
        {
          data: params,
          clientMsg: 'TRANS__ERROR__EXECUTING_SERVICE',
          type: method,
          contentType: contentType,
          skipApiPath: skipApiPath,
          stream: stream
        }, resolve, reject)
    })
  }

  getJobStatus(): Observable<any> {
    return this.http.get(
      this.getApiUrl('jobstatus/', true),
      {
        headers: this.getHeaders()
      }
    ).pipe(
      catchError((error: any) => {
        if (error.status === 401) {
          this.primusBackendInstanceService.setRefreshToken();
        }
        return throwError(() => new Error(error));
      })
    );
  }

  downloadReport(reportId): Observable<any> {
    return this.http.get(
      this.getApiUrl(`jobstatus/download/report/${reportId}`, true),
      {
        responseType: 'blob',
        headers: this.getHeaders()
      });
  }

  async fetchImageUrlFromDmsId(dmsId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.execFetch('dms/image_url/' + dmsId, {}, resolve, reject);
    });
  }

  async requestReportPdfFromDms(reportId): Promise<any> {
    console.log('----- requestReportPdfFromDms ----', reportId);

    return new Promise((resolve, reject) => {
      this.execFetch(`report/order_download_url/${reportId}`, {
        acceptContentType: 'application/json',
      }, resolve, reject);
    });

  }


  async getImportFields(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('import/import_fields',
        {
          clientMsg: 'TRANS__ERROR__GETTING_IMPORT_FIELDS',
        }, resolve, reject)
    })
  }

  async getUserDamsAccess(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('dams/user_dams_access',
        {
          clientMsg: 'TRANS__ERROR__GETTING_USER_DAMS_ACCESS',
          suppressErrHandler: true
        }, resolve, reject)
    })
  }

  async searchDams(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('dams/search', {
        data: params,
        clientMsg: 'TRANS__ERROR__SEARCHING_DAMS',
        type: 'POST',
        contentType: 'application/json',
        suppressErrHandler: true
      }, resolve, reject)
    })
  }

  async getDamsMetadata(damsId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('dams/metadata/' + damsId,
        {
          clientMsg: 'TRANS__ERROR__GETTING_DAMS_METADATA',
        }, resolve, reject)
    })
  }

  async getDamsStatistics(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.execFetch('dams/statistics/' + id,
        {
          clientMsg: 'TRANS__ERROR__GETTING_DAMS_STATISTICS',
          suppressErrHandler: true
        }, resolve, reject)
    })
  }

  async createImportDataFromDamsMetadata(params: any): Promise<DamsImportData> {
    return new Promise((resolve, reject) => {
      this.execFetch('dams/create_import_data_from_dams_metadata',
        {
          data: params,
          clientMsg: 'TRANS__ERROR__GETTING_IMPORT_DATA_FROM_DAMS_METADATA',
          type: 'POST',
          contentType: 'application/json'
        }, resolve, reject);
    });
  }

  getHeaders(contentType?: string) {
    return new HttpHeaders({
      'Content-Type': contentType || '',
      'Authorization': 'Bearer ' + this.accessTokenService.getToken(),
      'Id-Token': this.accessTokenService.getIdToken(),
      'Correlation-Id': uuid()
    });
  }

  async getDashboardCategories(path: string): Promise<{ items: DashboardListCategory[] }> {
    return new Promise((resolve, reject) => {
      this.execFetch('dashboard/list_categories', {
        data: {path},
      }, resolve, reject)
    })
  }

  private runRequest(httpParams): Observable<object> {
    const url = CmsApiService.getUrl(httpParams);
    const httpOptions = {headers: httpParams.httpHeaders};
    if (httpParams.method === 'POST') {
      return this.http.post(url, httpParams.data, httpOptions);
    } else if (httpParams.method === 'DELETE') {
      return this.http.delete(url, httpOptions);
    } else {
      return this.http.get(url, httpOptions);
    }
  }

  private async execFetch(url: string, params: any, resolve: Function, reject: Function) {
    let numRetries = 1;
    let rejectedResponse = null;

    if (!params.type) {
      params.type = 'GET';
    }
    const canRetry = params.type === 'GET' || params.forceRetries === true;
    if (canRetry) {
      numRetries = 3;
    }

    const checkTheHealthIfWeAreTooSlowTimeout = setTimeout(() => {
      if (isDevMode()) {
        console.debug(`[cms-api-service]: ${url} took too long, checking hibernation status...`);
      }
      this.hibernationService.healthCheck();
    }, TIMEOUT_TO_CHECK_HEALTH_AFTER_SLOW_CMS_API_MS);

    let startTime;
    const httpHeaders = this.buildHttpHeader(params)
    let data = params.data && (params.type === 'POST' || params.type === 'PUT') ? params.data : undefined;
    let queryString = params.type === 'GET' && params.data ? this.toQueryParams(params.data) : undefined;
    startTime = new Date().getTime();
    let fetchUrl = this.getFetchUrl(url, !params.skipApiPath, queryString);
    let hasTried = false;
    while (numRetries > 0) {
      if (hasTried) { // Delay only if it's a retry
        await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay
      }
      numRetries--;
      hasTried = true;
      let rawResponse: Response;
      try {
        rawResponse = await fetch(fetchUrl, {
          method: params.type,
          headers: httpHeaders,
          body: JSON.stringify(data)
        });
      } catch (e) {
        this.logger.error('Error in fetch', e);
        if (!params.suppressErrHandler && this.errHandler) {
          this.errHandler(e, params);
        }
        reject(e);
        return;
      } finally {
        clearTimeout(checkTheHealthIfWeAreTooSlowTimeout);
      }

      if (!rawResponse.ok) {
        // Error could be because of hibernation, make sure everything is healthy before we try again
        await this.hibernationService.healthCheck();
        rejectedResponse = rawResponse;
        //Log or do something with the error
        this.logger.info('Error in fetch', rawResponse);
        let error = await rawResponse.text();
        if (error?.startsWith('{')) {
          error = JSON.parse(error).message;
          rejectedResponse.message = error;
        }
        const status = rawResponse.status;
        if (status === 408) {
          rejectedResponse['Correlation-Id'] = httpHeaders['Correlation-Id'];
          reject(rejectedResponse);
          return;
        }
        this.logger.info(`Call failed for url ${fetchUrl}, error "${error}", remaining retries: ${numRetries}, correlation_id: ${httpHeaders['Correlation-Id']}`);
        if (status === 422 && error) {
          this.logger.error('FieldValidation failed:', error);
        }
      } else if (rawResponse.status === 204) {
        //OK response, but not content
        numRetries = 0;
        rejectedResponse = null;

        const httpCallDuration = new Date().getTime() - startTime;
        if (httpCallDuration > 6000) {
          this.logger.warn(`Executing ${url} took ${httpCallDuration} ms. You need to look into that! 🐢`);
        }

        resolve();
      } else {
        numRetries = 0;
        rejectedResponse = null;
        let data = null;
        if (params.stream) {
          data = rawResponse.body.getReader();
        } else if (params.acceptContentType === 'application/pdf') {
          data = await rawResponse.blob();
        } else {
          data = await rawResponse.json();
        }
        const httpCallDuration = new Date().getTime() - startTime;
        if (httpCallDuration > 6000) {
          this.logger.warn(`Executing ${url} took ${httpCallDuration} ms. You need to look into that! 🐢`);
        }
        resolve(data);
      }
    }



    if (rejectedResponse) {
      const retryText = canRetry ? '3 times ' : '';
      this.logger.error(`Call failed ${retryText}for url ${fetchUrl}, correlation_id: ${httpHeaders['Correlation-Id']}`);
      rejectedResponse.correlationId = httpHeaders['Correlation-Id'];

      if (!params.suppressErrHandler && this.errHandler) {
        this.errHandler(rejectedResponse, params);
      }
      reject(rejectedResponse);
    }
  }

  private async execStream(url: string, params: any, resolve: Function, reject: Function) {
    let numRetries = 3;
    let rejectedResponse: any = null;

    if (!params.type) {
      params.type = 'GET';
    }

    // Sett opp en timeout for å sjekke hibernation hvis kallet tar for lang tid
    const checkTheHealthIfWeAreTooSlowTimeout = setTimeout(() => {
      if (isDevMode()) {
        console.debug(`[cms-api-service]: ${url} tar for lang tid, sjekker hibernation status...`);
      }
      this.hibernationService.healthCheck();
    }, TIMEOUT_TO_CHECK_HEALTH_AFTER_SLOW_CMS_API_MS);

    const startTime = new Date().getTime();
    const httpHeaders = this.buildHttpHeader(params);
    const data = params.data && (params.type === 'POST' || params.type === 'PUT') ? params.data : undefined;
    const queryString = params.type === 'GET' && params.data ? this.toQueryParams(params.data) : undefined;
    const fetchUrl = this.getFetchUrl(url, !params.skipApiPath, queryString);
    let hasTried = false;

    while (numRetries > 0) {
      if (hasTried) {
        await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sekund delay ved retry
      }
      numRetries--;
      hasTried = true;

      let rawResponse: Response;
      try {
        rawResponse = await fetch(fetchUrl, {
          method: params.type,
          headers: httpHeaders,
          body: params.type === 'GET' ? undefined : JSON.stringify(data)
        });
      } catch (e) {
        this.logger.error('Error in fetch', e);
        if (!params.suppressErrHandler && this.errHandler) {
          this.errHandler(e, params);
        }
        reject(e);
        return;
      } finally {
        clearTimeout(checkTheHealthIfWeAreTooSlowTimeout);
      }

      if (!rawResponse.ok) {
        // Sjekk hibernation-status hvis responsen ikke er OK
        await this.hibernationService.healthCheck();
        rejectedResponse = rawResponse;
        let error = await rawResponse.text();
        if (error?.startsWith('{')) {
          error = JSON.parse(error).message;
          rejectedResponse.message = error;
        }
        const status = rawResponse.status;
        if (status === 408) {
          rejectedResponse['Correlation-Id'] = httpHeaders['Correlation-Id'];
          reject(rejectedResponse);
          return;
        }
        this.logger.info(`Call failed for url ${fetchUrl}, error "${error}", remaining retries: ${numRetries}, correlation_id: ${httpHeaders['Correlation-Id']}`);
        if (status === 422 && error) {
          this.logger.error('FieldValidation failed:', error);
        }
      } else if (rawResponse.status === 204) {
        // OK-respons, men ingen innhold
        numRetries = 0;
        rejectedResponse = null;
        const httpCallDuration = new Date().getTime() - startTime;
        if (httpCallDuration > 6000) {
          this.logger.warn(`Executing ${url} tok ${httpCallDuration} ms. Vurder oppsettet! 🐢`);
        }
        resolve();
        return;
      } else {
        // Streaming-respons: les dataene via reader
        numRetries = 0;
        rejectedResponse = null;
        try {
          const reader = rawResponse.body.getReader();
          const result = await this.readStream(reader);
          const httpCallDuration = new Date().getTime() - startTime;
          if (httpCallDuration > 6000) {
            this.logger.warn(`Executing ${url} tok ${httpCallDuration} ms. Vurder oppsettet! 🐢`);
          }

          resolve(result);
          return;
        } catch (e) {
          reject(e);
          return;
        }
      }
    }

    if (rejectedResponse) {
      const retryText = '3 ganger ';
      this.logger.error(`Call failed ${retryText}for url ${fetchUrl}, correlation_id: ${httpHeaders['Correlation-Id']}`);
      rejectedResponse.correlationId = httpHeaders['Correlation-Id'];
      if (!params.suppressErrHandler && this.errHandler) {
        this.errHandler(rejectedResponse, params);
      }
      reject(rejectedResponse);
    }
  }

// Hjelpefunksjon for å lese og avkode streamen
  private async readStream(reader: ReadableStreamDefaultReader<Uint8Array>): Promise<any> {
    const decoder = new TextDecoder();
    let result = '';

    while (true) {
      const { done, value } = await reader.read();
      let chunk = '';
      if (value) {
        chunk = decoder.decode(value, { stream: true });
        //this.logger.debug(`Mottatt chunk: ${chunk}`);
        result += chunk;

        // Dersom den mottatte chunken, etter trimming, er eksakt "]"
        if (chunk.trim() === ']') {
          this.logger.debug('Avsluttende chunk mottatt, avslutter lesing.');
          break;
        }
      }

      // Hvis streamen signaliserer at den er ferdig
      if (done) {
        // Her kan vi logge en advarsel dersom resultatet ikke ser komplett ut
        if (!result.trim().endsWith(']')) {
          this.logger.warn('Streamen er avsluttet, men JSON-resultatet ser ut til å være ufullstendig.');
        }
        break;
      }
    }

    // Flush eventuelle gjenværende bytes
    result += decoder.decode();
    this.logger.debug(`Samlet stream-resultat: ${result}`);

    try {
      return JSON.parse(result);
    } catch (error) {
      this.logger.error('JSON-parsing feilet for stream-resultatet', error);
      throw error;
    }
  }





  private buildHttpHeader(params) {
    return {
      'Content-Type': params.contentType || '',
      'Accept': params.acceptContentType || 'application/json',
      'Authorization': 'Bearer ' + this.accessTokenService.getToken(),
      'Id-Token': this.accessTokenService.getIdToken(),
      'Correlation-Id': uuid()
    };
  }

  private toQueryParams(data: Record<string, string | string[]>): string {
    const queryParams: string[] = [];

    Object.keys(data).forEach(key => {
      const value = data[key];
      if (Array.isArray(value)) {
        value.forEach(() => {
          const arrayValues = value.join(',');
          queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(arrayValues)}`);
        });
      } else if (value !== undefined && value !== null) {
        queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    });

    if (queryParams.length > 0) {
      return '?' + queryParams.join('&');
    }

    return undefined;
  }


  private execAjax(url, params, resolve, reject) {
    let startTime;
    const httpHeaders = this.getHeaders(params.contentType);
    const addApiPath = !params.skipApiPath;
    const httpParams = {
      method: params.type,
      url: this.getApiUrl(url, addApiPath),
      cache: false,
      withCredentials: false,
      header: null,
      data: null,
      params: null,
      contentType: params.contentType,
      httpHeaders: httpHeaders
    };
    if (params.data) {
      if ((params.type === 'POST' || params.type === 'PUT') &&
        params.contentType === 'application/json') {
        httpParams.data = params.data;
      } else {
        httpParams.params = params.data;
      }
    }
    startTime = new Date().getTime();
    this.runRequest(httpParams)
      .subscribe({
          next: (data: any) => {
            this.afterExecuted(startTime, url, data, httpParams, reject, params, resolve);

          },
          error: (response: HttpErrorResponse) => {
            const error = response.error;
            const status = response.status;
            this.logger.error(`Call failed for url ${url} with parameters:`, httpParams);
            if (status === 422 && error) {
              this.logger.error('FieldValidation failed:', error);
            }
            if (!params.suppressErrHandler && this.errHandler) {
              this.errHandler(response, params);
            }
            if (reject) {
              reject(response);
            }
          }
        });
  }

  private afterExecuted(startTime, url, data: any, httpParams, reject, params, resolve) {
    let err;
    const endTime = new Date().getTime() - startTime;
    if (endTime > 6000) {
      this.logger.warn(`Executing ${url} took ${endTime} ms. You need to look into that! 🐢`);
    }
    if (typeof data === 'string' && data.startsWith('<!DOCTYPE html>')) {
      err = {
        data: {
          message: 'Probably redirected to login page'
        },
        status: 307
      };
      this.logger.error(err.data.message, httpParams);
      if (reject) {
        reject(err);
      }
      this.logout().then(
        () => {
          if (this.loginFn) {
            this.loginFn(() => {
              // Try to repeat request after
              // login
              this.execAjax(url, params, resolve,
                reject);
            });
          }
        },
        () => {
          throw new Error('Unable to log out!');
        }
      );
    } else if (resolve) {
      resolve(data);
    }
  }
}
