import {Component, input, isDevMode, OnDestroy, OnInit, signal, WritableSignal} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { ExtendedSearchService } from '../../core/extended-search.service';
import { SearchContainer } from '../../core/definitions/search-container';
import {
  ExtendedSearchQueryBuilderGroupComponent
} from './extended-search-query-builder-group/extended-search-query-builder-group.component';
import { SearchExecutorService } from '../search-executor.service';
import { MatChip } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import {
  InformationModalComponent,
  InformationModalData
} from '../../shared/information-modal/information-modal.component';
import { SearchService } from '../../core/search.service';
import {ExtendedSearchDbDialogComponent} from "./extended-search-db-dialog/extended-search-db-dialog.component";
import * as Sentry from '@sentry/angular';
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-extended-search-query-builder',
  standalone: true,
  imports: [
    MatButton,
    TranslateModule,
    MatIcon,
    ExtendedSearchQueryBuilderGroupComponent,
    MatChip,
    MatTooltip
  ],
  templateUrl: './extended-search-query-builder.component.html',
  styleUrl: './extended-search-query-builder.component.scss'
})
export class ExtendedSearchQueryBuilderComponent implements OnDestroy, OnInit {
  correctTab = false;
  dialogRef = null;
  searchContainer = input.required<SearchContainer>();
  isSearching = false;
  private searchPromise: Promise<any> | null = null;

  searchFailed: WritableSignal<boolean> = signal<boolean>(false);

  private startTime = null;
  private endTime = null;

  constructor (
    public readonly extendedSearchService: ExtendedSearchService,

    private readonly modal: MatDialog,
    private readonly searchExecutor: SearchExecutorService,
    private readonly searchService: SearchService
  ) {}

  ngOnInit () {
    if (!localStorage.getItem('primus.searchOngoing')) {
      localStorage.setItem('primus.searchOngoing', 'false');
    }
    if (this.searchContainer().queryContainer.query !== '') {
      this.searchContainer().queryContainer.query = '';
    }

    this.searchExecutor.subscribeToSearchResult(this.searchContainer(), this.handleSearchChange)

    this.extendedSearchService.initializeOrCreateSearchParametersFromSearchContainer(this.searchContainer());
  }

  ngOnDestroy() {
    this.searchExecutor.unSubscribeToSearchResult(this.searchContainer(), this.handleSearchChange);
  }

  get ongoingSearch(): boolean {
    if (localStorage.getItem('primus.searchOngoing') && localStorage.getItem('primus.searchOngoing') !== 'false') {
      const timeStamp = new Date(parseInt(localStorage.getItem('primus.searchOngoing')));
      const now = new Date();
      const diff = now.getTime() - timeStamp.getTime();
      return diff < 300000;
    }

    return localStorage.getItem('primus.searchOngoing') !== 'false';
  }

  get rulesHasValues(): boolean {
    return this.extendedSearchService.getRulesHasValues();
  }

  addNewGroupToRoot() {
    this.extendedSearchService.addNewGroupToRoot();
  }

  resetExtendedSearch() {
    this.extendedSearchService.resetExtendedSearch();

    this.extendedSearchService.triggerFieldSelectorUpdate();
  }

  triggerExtendedSearch() {
    this.searchFailed.set(false);
    if (!this.rulesHasValues) {
      this.modal.open(InformationModalComponent, {
        data: {
          title: 'TRANS__ADVANCED_SEARCH__NO_RULES_DEFINED_TITLE',
          content: 'TRANS__ADVANCED_SEARCH__NO_RULES_DEFINED_CONTENT',
          buttonText: 'TRANS__ADVANCED_SEARCH__NO_RULES_DEFINED_OK'
        }
      });
    }
    else {
      this.startTime = performance.now();

      this.isSearching = true;
      this.searchPromise = this.searchExecutor.runSearch(this.searchContainer())
        .then((result) => {
          if (result.status !== 'ok') {
            this.endTime = performance.now();
            if (this.extendedSearchService.extendedSearchParameters().db_search && !isDevMode()) {
              Sentry.captureMessage('[EXTENDED_SEARCH] DB Search timed out or failed: ' + result.correlation_id + ' - ' + result.status_msg);
            }

            this.searchFailed.set(true);
          }
          else {
            this.endTime = performance.now();
            if (!isDevMode() && this.extendedSearchService.extendedSearchParameters().db_search) {
              Sentry.captureMessage('[EXTENDED_SEARCH] Finished in ' + (this.endTime - this.startTime) + 'ms: ' + result.correlation_id + ' - ' + result.status_msg);
            }
          }

          this.startTime = null;
          this.endTime = null;

          this.isSearching = false;
          this.searchPromise = null;
          return result;
        })
        .catch((error) => {
          this.isSearching = false;
          this.searchPromise = null;
          throw error;
        });

      if (this.extendedSearchService.extendedSearchParameters().db_search) {
        // "This is the correct version of the component that will also set localStorage flag false when done"
        this.correctTab = true;
        localStorage.setItem('primus.searchOngoing', Date.now().toString());

        this.dialogRef = this.modal.open(ExtendedSearchDbDialogComponent, {
          panelClass: 'extended-search__db-dialog',
          disableClose: true,
          data: {
            failed: this.searchFailed
          }
        });

        this.dialogRef.afterClosed().subscribe((cancel: boolean) => {
          if (cancel) {
            this.cancelSearch();
          }
        });
      }
    }
  }

  handleSearchChange = () => {
    if (!this.searchContainer()?.searching && this.correctTab) {

      if (this.dialogRef !== null && !this.searchFailed()) {
        this.dialogRef.close(true);
        this.dialogRef = null;
      }

      localStorage.setItem('primus.searchOngoing', 'false');
    }
  }

  cancelSearch() {
    if (this.isSearching) {
      this.searchService.cancelDbCachePolling(this.searchContainer().dbSearchCorrelationId).then();
    }

    if (this.dialogRef !== null && !this.searchFailed()) {
      this.dialogRef.close(true);
      this.dialogRef = null;
    }

    localStorage.setItem('primus.searchOngoing', 'false');
  }

  cancelDbDialog = () => {
    // If this is the tab which currently has a ongoing search which just finished
    if (this.correctTab) {
      this.correctTab = false;

      localStorage.setItem('primus.searchOngoing', 'false');
    }
  }
}
