@if (searchContainer.searching && searchContainer.extendedSearchParams?.db_search) {
  <div class="search-result__loader"></div>
}
@else if (searchContainer.searching && !searchContainer.extendedSearchParams?.db_search) {
  <div class="search-result__loader">
    <mat-spinner diameter="54"></mat-spinner>
    <span>{{ 'TRANS__SEARCH__LOADING_INFO' | translate }}</span>
  </div>
}
@else {
  @if (searchContainer.searchResult?.search_count === 0) {
    <div class="search-result__no-results">
      <div class="search-result__no-results-container">
        <mat-icon>search_off</mat-icon>
        <p class="search-result__no-results-heading" translate="TRANS__SEARCH_RESULT__NO_SEARCH_HITS"></p>
      </div>
    </div>
  }

  @if (searchContainer.searchResult?.search_count > 0) {
    <div
      class="search-result__container"
      [ngClass]="{
        'search-result__container--thumbnails' : (searchContainer.searchResultViewName === viewNames.THUMBNAIL),
        'search-result__container--gallery' : (searchContainer.searchResultViewName === viewNames.GALLERY),
        'search-result__container--list-thumbnails' : (searchContainer.searchResultViewName === viewNames.LIST_THUMBNAIL),
        'search-result__container--list' : (searchContainer.searchResultViewName === viewNames.LIST)
      }"
    >
      @for (artifact of searchContainer.searchResult.artifacts; track $index) {
        @switch (searchContainer.searchResultViewName) {
          @case (viewNames.THUMBNAIL) {
            <app-search-result-view-thumbnail-v2
              [isRootObject]="isRootObject(artifact.artifact_id)"
              [item]="artifact"
              [searchContainer]="searchContainer"
            />
          }

          @case (viewNames.GALLERY) {
            <app-search-result-view-gallery-v2
              [isRootObject]="isRootObject(artifact.artifact_id)"
              [item]="artifact"
              [searchContainer]="searchContainer"
            />
          }

          @case (viewNames.LIST_THUMBNAIL) {
            <app-search-result-view-thumbnail-list-v2
              [isRootObject]="isRootObject(artifact.artifact_id)"
              [item]="artifact"
              [searchContainer]="searchContainer"
            />
          }
        }
      }

      @if(searchContainer.searchResultViewName === viewNames.LIST && !searchContainer.searching) {
        <app-search-result-view-list-v2
          [rootObjectId]="rootObjectId"
          [searchContainer]="searchContainer"
        />
      }
    </div>
  }
}
